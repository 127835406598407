const language = window.appLanguage

let api = {
  domain: 'https://wirtualnycmentarz.test',
  url: 'https://wirtualnycmentarz.test/api/',
  grave: 'https://wirtualnycmentarz.test/cmentarz/kwatera/:id',
  catacombs: 'https://wirtualnycmentarz.test/katakumby/kwatera/:id',
  animals: 'https://wirtualnycmentarz.test/cmentarz-zwierzat/kwatera/:id',
  ttl: 60
}
if (process.env.NODE_ENV === 'production') {
  if (window.location.hostname === 'panel.wirtualnycmentarz.pl' ||
    window.location.hostname === 'www.panel.wirtualnycmentarz.pl' ||
    window.location.hostname === 'panel.virtualgrave.eu' ||
    window.location.hostname === 'www.panel.virtualgrave.eu') {
    api.domain = 'https://api.wirtualnycmentarz.pl'
    api.url = 'https://api.wirtualnycmentarz.pl/api/'
    api.grave = 'https://wirtualnycmentarz.pl/cmentarz/kwatera/:id'
    api.catacombs = 'https://wirtualnycmentarz.pl/katakumby/kwatera/:id'
    api.animals = 'https://wirtualnycmentarz.pl/cmentarz-zwierzat/kwatera/:id'
    if (language === 'en') {
      // api.domain = 'https://api.wirtualnycmentarz.pl'
      // api.url = 'https://api.wirtualnycmentarz.pl/api/'
      api.grave = 'https://virtualgrave.eu/graveyard/lodging/:id'
      api.catacombs = 'https://virtualgrave.eu/catacombs/lodging/:id'
      api.animals = 'https://virtualgrave.eu/animals/lodging/:id'
    }
  } else if (window.location.hostname === 'panel.nezabudneme.sk' ||
    window.location.hostname === 'www.panel.nezabudneme.sk') {
    api.domain = 'https://api.nezabudneme.sk'
    api.url = 'https://api.nezabudneme.sk/api/'
    api.grave = 'https://nezabudneme.sk/cintorin/ubytovanie/:id'
    api.catacombs = 'https://nezabudneme.sk/katakomby/ubytovanie/:id'
    api.animals = 'https://nezabudneme.sk/zvierata-cintorin/ubytovanie/:id'
  } else {
    api.domain = 'https://api.cmentarz.usermd.net'
    api.url = 'https://api.cmentarz.usermd.net/api/'
    api.grave = 'https://cmentarz.usermd.net/cmentarz/kwatera/:id'
    api.catacombs = 'https://cmentarz.usermd.net/katakumby/kwatera/:id'
    api.animals = 'https://cmentarz.usermd.net/cmentarz-zwierzat/kwatera/:id'
  }
}

export default {
  my: api
}

const language = {
  'global': {
    'app': 'nezabudneme.sk',
    'not-implemented': 'Bude implementované ...',
    'saved': 'Údaje boli uložené',
    'removed': 'Záznam bol odstránený',
    'archived': 'Archivovaný záznam',
    'activated': 'Záznam bol aktivovaný',
    'amount': 'Množstvo',
    'ok': 'OK',
    'cancel': 'Zrušiť',
    'save': 'Uložiť',
    'yes': 'Áno',
    'no': 'Nie',
    'clear': 'Vyčistiť',
    'remove': 'Odstrániť',
    'close': 'Zavrieť',
    'generate': 'Generovať',
    'addImage': 'Pridať obrázok',
    'comment': 'Komentár',
    'saveAndBack': 'Uložiť a vrátiť',
    'chooseColumns': 'Vyberte stĺpce',
    'indefinitely': 'Na neurčito',
    'atLeastThree': 'Zadajte aspoň 3 znaky',
    'search': 'Hľadať',
    'buriedPeople': 'Pochovaný v hrobe',
    'ownerAll': 'Majte na pamäti, že meníte vlastníka všetkých ľudí pochovaných v hroboch.',
    'required': 'Požadovaný.'
  },
  'languages': [
    {
      value: 'pl',
      text: 'Polski'
    }, {
      value: 'en',
      text: 'English'
    }, {
      value: 'sk',
      text: 'Slovenský'
    }
  ],
  'forms': {
    'login': {
      'emailPhone': 'Email / Číslo telefónu',
      'forgotPassword': 'Zabudli ste heslo??',
      'password': 'Heslo',
      'rememberMe': 'Pamätať si ma',
      'login': 'Prihlásiť',
      'loginScreen': 'Prihlásiť sa',
      'remindPassword': 'Pripomenúť heslo',
      'metaTitle': 'Prihlásiť sa'
    }
  },
  'menu': {
    'dashboard': 'Kokpit',
    'transactions': 'Transakcie',
    'deadBook': 'Kniha mŕtvych ľudí',
    'animalBook': 'Kniha mŕtvych zvierat',
    'tombstones': 'Náhrobné kamene',
    'tombstoneAdditions': 'Príslušenstvo k náhrobkom',
    'users': 'Používatelia',
    'admins': 'Správcovia',
    'partners': 'Partneri',
    'ads': 'Reklamy',
    'pricelist': 'Cenník',
    'pricelistFuneralHouses': 'Cenník partnerov',
    'reports': 'Podania',
    'sites': 'Strany',
    'logout': 'Odhlásiť sa'
  },
  'paidOptions': [
    {
      'value': null,
      'text': 'Všetky'
    },
    {
      'value': 'zaplatené',
      'text': 'Iba platené'
    },
    {
      'value': 'zadarmo',
      'text': 'Len bezplatné'
    }
  ],
  'contents': {
    'dashboard': {
      'title': 'Štatistiky',
      'mainStatistics': 'Pohreb na hlavnom cintoríne',
      'catacombsStatistics': 'Pohreb v katakombách',
      'animalStatistics': 'Pohreb na cintoríne zvierat',
      'registeredUsers': 'Registrovaní užívatelia',
      'religion': 'Náboženstvo',
      'mainCemetery': 'Cintorín',
      'catacombs': 'Katakomby',
      'last24h': 'Ostatné 24h',
      'last7days': 'Ostatné 7 dní',
      'lastMonth': 'Ostatné mesiac',
      'lastYear': 'Ostatné rok',
      'registeredInTime': 'Zaregistrovaný v čase',
      'loggedInTime': 'Prihlásený v čase',
      'funeralsPaid': 'Platené pohreby',
      'funeralsFree': 'Pohreby zadarmo',
      'additionsPaid': 'Platené doplnky',
      'additionsFree': 'Príplatky zadarmo'
    },
    'transactions': {
      'title': 'Transakcie',
      'transactionId': 'ID transakcie',
      'paidUntil': 'Zaplatené do',
      'user': 'Používateľ',
      'orderType': 'Typ nákupu',
      'gateway': 'Platobná brána',
      'price': 'čiastka',
      'created': 'Vytvorené',
      'export_invoices': 'Exportovať faktúry',
      'total': 'Celkom',
      'paid_filter': 'Typ transakcie',
      'room': 'Hrob',
      'finished': 'dokončené',
      'unpaid': 'nezaplatené',
      'removeModal': {
        'title': 'Vymazanie transakcie',
        'elements': 'Ktoré položky chcete odstrániť?',
        'all': 'Transakcie a súvisiace veci (hrob a ľudia / suveníry)',
        'transaction': 'Iba transakcie'
      },
      'invoices': {
        'date_from': 'Dátum od',
        'date_to': 'Dátum do',
        'funeral_company': 'Partner',
        'pick_one': 'Filter',
        'get_invoices_link': 'Stiahnite si faktúry vo formáte PDF',
        'get_edipp_link': 'Export faktúr do formátu Edi++',
        'get_edipp_link_disabled': 'Ak chcete exportovať faktúry do formátu Edi++, musíte zvoliť „dátum od“ a „dátum do“',
        'amount_of_invoices': 'Počet faktúr vo vybranom rozsahu',
        'file': 'faktúry',
        'single': 'faktúra',
        'none': 'nedostatok'
      },
      'actions': {
        'title': 'Akcie',
        'invoice': 'Faktúra',
        'edit': 'Upraviť',
        'remove': 'Odstrániť'
      },
      'export_transactions': 'Účtovný výkaz',
      'export': {
        'with_invoices': 'S faktúrami',
        'without_invoices': 'Žiadne faktúry',
        'refunds': 'Vrátiť',
        'get_transactions_link': 'Stiahnite si prehľad',
        'file': 'export'
      }
    },
    'deadBook': {
      'title': 'Kniha mŕtvych',
      'add': 'Pridajte pohreb',
      'filter': 'Filter',
      'created': 'Dátum pridania',
      'name': 'Meno',
      'surname': 'Priezvisko',
      'nick': 'Pseudonym',
      'sex': 'Pohlavie',
      'birthday': 'Dátum narodenia',
      'death': 'Smrť',
      'place': 'Miesto',
      'link': 'Link',
      'actions': {
        'title': 'Akcie',
        'edit': 'Upraviť',
        'move': 'Zmeniť vlastníka',
        'remove': 'Odstrániť'
      },
      'showModal': {
        'close': 'Zavrieť',
        'edit': 'Úprava pohrebu',
        'add': 'Pridanie pohrebu',
        'name': 'Meno',
        'surname': 'Priezvisko',
        'nick': 'Pseudonym',
        'sex': 'Pohlavie',
        'birthday': 'Dátum narodenia',
        'death': 'Dátum úmrtia',
        'memorial': 'Podpis',
        'memorial_text': 'Obsah',
        'ownerName': 'Meno majiteľa',
        'ownerSurname': 'Priezvisko majiteľa',
        'ownerEmail': 'E-mail majiteľa',
        'ownerPhone': 'Telefón majiteľa',
        'emailContent': 'Obsah e-mailu',
        'smsContent': 'Obsah SMS',
        'variables': 'Premenné',
        'varName': 'meno zosnulého',
        'varSurname': 'priezvisko zosnulého',
        'varLink': 'odkaz, pod ktorým bude pohreb umiestnený',
        'portrait': 'Portrét',
        'album': 'Album',
        'autoSend': 'Po vyplnení sa odosiela automaticky'
      },
      'moveModal': {
        'title': 'Zmena majiteľa',
        'sourceUser': 'Teraz majiteľ',
        'targetUser': 'Budúci majiteľ'
      }
    },
    'animalBook': {
      'title': 'Kniha zvierat'
    },
    'tombstones': {
      'title': 'Náhrobky',
      'allCemeteries': 'Všetky cintoríny',
      'allReligions': 'Všetky náboženstvá',
      'allTombstoneTypes': 'Všetky druhy hrobov',
      'ordering': 'Triedenie náhrobných kameňov',
      'add': 'Pridajte náhrobok',
      'id': 'ID',
      'image': 'Obrázok',
      'place': 'Miesto',
      'religion': 'Náboženstvo',
      'species': 'Typ',
      'type': 'Typ',
      'used': 'Použité časy',
      'actions': {
        'title': 'Akcie',
        'edit': 'Editovať',
        'remove': 'Odstrániť'
      },
      'newTombstone': 'Nový náhrobok',
      'editTombstone': 'Úpravy náhrobku',
      'chooseAllFilters': 'Vyberte všetky filtre, aby ste videli poradie na stránke. Pohybom fotografie sa objednávka automaticky uloží.'
    },
    'tombstoneAdditions': {
      'title': 'Doplnky pre hroby',
      'image': 'Obrázok',
      'type': 'Typ',
      'all': 'Všetky typy',
      'add': 'Pridajte doplnok',
      'addTypes': 'Pridať typy',
      'used': 'Použité časy',
      'actions': {
        'title': 'Akcie',
        'edit': 'Editovať',
        'remove': 'Odstrániť'
      },
      'newTombstoneAddition': 'Nový dodatok',
      'editTombstoneAddition': 'Editovať dodatok'
    },
    'users': {
      'representative': 'Reprezentatívny',
      'address': 'Adresa',
      'url': 'WWW',
      'tax_id': 'DIČ',
      'title': 'Používatelia',
      'user': 'Používateľ',
      'addUser': 'Pridať používateľa',
      'id': 'ID',
      'surname': 'Názov',
      'surnameName': 'Priezvisko / Názov spoločnosti',
      'companyName': 'Názov spoločnosti',
      'discount': 'Zľava - pohreby (0-100%)',
      'souvenirs_discount': 'Zľava - suveníry (0-100%)',
      'nick': 'Pseudonym',
      'name': 'Meno',
      'email': 'Email',
      'phone': 'Telefón',
      'emailConfirmed': 'Email potvrdený dňa',
      'activeTombstones': 'Aktívne pohreby',
      'activeTombstonesShort': 'AK.P.',
      'archiveTombstones': 'Archívne pohreby',
      'archiveTombstonesShort': 'AR.P.',
      'additions': 'Dodatky',
      'registration': 'Registrácia',
      'lastLogin': 'Posledné prihlásenie',
      'emailVerified': 'Overený účet',
      'emailVerifiedShort': 'KZ',
      'agreedNotifications': 'Súhlas s upozorneniami',
      'agreedNotificationsShort': 'ZP',
      'agreedEmails': 'Súhlas s e-mailami',
      'agreedEmailsShort': 'ZE',
      'role': 'Rola',
      'newPassword': 'Nové heslo',
      'newPasswordAgain': 'Zopakujte nové heslo',
      'newPasswordWillBeSent': 'Nové heslo bude zaslané e-mailom.',
      'lastLogged': 'Nedávno prihlásený',
      'tombstoneValid': 'Platnosť pohrebu',
      'validTo': 'Platné do',
      'menuAccess': 'Prístup do menu',
      'confirmDeleteTitle': 'Potvrdenie o vymazaní',
      'confirmDelete': 'Naozaj chcete tohto používateľa odstrániť? Tento používateľ má: <strong> {0} </strong> aktívne pohreby, <strong> {1} </strong> archívne pohreby a <strong> {2} </strong> doplnky.',
      'monthlyInvoices': 'Mesačné faktúry',
      'editUser': 'Upraviť používateľa',
      'randomPassword': 'Ak nezadáte heslo, novému používateľovi sa e-mailom pošle náhodné heslo.',
      'actions': {
        'title': 'Akcie',
        'edit': 'Editovať',
        'impersonate': 'Prihlásiť sa ako',
        'remove': 'Odstrániť',
        'suspend': 'Pozastaviť účet',
        'active': 'Aktivovať účet',
        'newPassword': 'Zaslať nové heslo',
        'confirmRegistration': 'Zaslať potvrdenie e-mailom'
      },
      'details': {
        'tombstones': {
          'name': 'Pohreby',
          'noSingleTombstones': 'Neexistujú žiadne hroby pre jednu osobu',
          'noMultiTombstones': 'Neexistujú žiadne hroby pre viac osôb',
          'noAnimalTombstones': 'Žiadne hroby zvierat',
          'nameSurname': 'Meno a priezvisko',
          'nick': 'Pseudonym',
          'death': 'Dátum úmrtia',
          'tombstoneId': 'Číslo hrobu',
          'addedDate': 'Dátum pridania (IP)',
          'validUntil': 'Zaplatené do',
          'link': 'Link',
          'inArchives': 'V archívoch',
          'linkName': 'Link do cintorína',
          'extend': 'Predložiť',
          'activate': 'Aktivovať',
          'archive': 'Archivovať',
          'remove': 'Odstrániť'
        },
        'transactions': {
          'name': 'Transakcie',
          'date': 'Dátum (IP)',
          'type': 'Typ',
          'price': 'Cena',
          'concerns': 'Týka sa',
          'noTransactions': 'Žiadna transakcia'
        },
        'affiliation': {
          'name': 'Affiliate',
          'urlPl': 'Link na affiliate [PL]',
          'urlEn': 'Link na affiliate [EN]',
          'urlSk': 'Link na affiliate [Sk]',
          'generateAffiliation': 'Vytvoriť pre používateľa partnerský token',
          'month': 'Mesiac',
          'monthlyIncome': 'Celkové výnosy za vybraný mesiac'
        },
        'invoices': {
          'name': 'Faktúry',
          'id': 'Číslo faktúry',
          'date': 'Dátum',
          'type': 'Typ',
          'price': 'Cena',
          'get_invoice': 'Stiahnuť ▼',
          'noInvoices': 'Žiadne faktúry'
        }
      }
    },
    'admins': {
      'name': 'Správcovia'
    },
    'partners': {
      'name': 'Partneri',
      'notificationHint': 'Oznámenia o nových partneroch budú zasielané na uvedenú e-mailovú adresu. Ak chcete vypnúť, nechajte pole prázdne.'
    },
    'ads': {
      'title': 'Reklamy',
      'addAd': 'Pridať inzerát',
      'name': 'Názov',
      'link': 'Link',
      'zone': 'Zóna',
      'showingFrom': 'Zobrazené od',
      'showingTo': 'Zobrazené do',
      'time': 'Rotácia',
      'actions': {
        'title': 'Akcia',
        'edit': 'Editovať',
        'remove': 'Odstrániť'
      },
      'newAd': 'Nová reklama',
      'editAd': 'Editácia reklamy',
      'rotation': 'Doba rotácie [s]',
      'image': 'Obrázok'
    },
    'pricelist': {
      'activeShort': 'A',
      'active': 'Aktívny?',
      'title': 'Názov',
      'validDays': 'Dôležité dni (0 - navždy)',
      'addPosition': 'Pridať položku',
      'remove': 'Odstrániť'
    },
    'reports': {
      'created': 'Dátum podania',
      'report': 'Oznámenie',
      'condolence': 'Kondolencia',
      'funeral': 'Pohreb',
      'lodging_no': 'Číslo hrobu',
      'actions': {
        'archive': 'Archivovať',
        'remove': 'Odstrániť'
      }
    },
    'sites': {
      'title': 'Strany',
      'name': 'Názov'
    }
  },
  'users': {
    'new-password-sent': 'Bolo odoslané nové heslo.',
    'confirmation-email-sent': 'E-mail bol odoslaný.',
    'account-unsuspended': 'Účet bol pozastavený.',
    'account-suspended': 'Účet pozastavený.',
    'account-suspended-title': 'Účet pozastavený.',
    'account-suspended-l': '(Z)',
    'password-changed': 'Heslo bolo zmenené. Teraz sa môžete prihlásiť.'
  },
  'places': {
    'main': 'Cintorín',
    'catacombs': 'Urny',
    'animal': 'Cintorín zvierat'
  },
  'transaction': {
    'type': {
      'tombstone': 'Pohreb',
      'addition': 'Dodatok'
    }
  },
  'genders': {
    'm': 'Muž',
    'f': 'Žena',
    'null': '-'
  },
  'gendersAnimals': {
    'm': 'm',
    'f': 'ž',
    'null': '-'
  },
  'roles': {
    'super-admin': 'Super Admin',
    'admin': 'Admin',
    'funeral-company': 'Partner',
    'user': 'Používateľ'
  },
  'permissions': {
    'transactions': 'Transakcie',
    'dead-book': 'Kniha mŕtvych ľudí',
    'animal-book': 'Kniha mŕtvych zvierat',
    'tombstones': 'Náhrobky',
    'tombstone-additions': 'Príslušenstvo k náhrobkom',
    'users': 'Používatelia',
    'admins': 'Správcovia',
    'partners': 'Partneri',
    'ads': 'Reklamy',
    'pages': 'Strany',
    'prices': 'Všeobecný cenník',
    'prices/funeral-house-prices': 'Cenník ppre partnerov'
  },
  'zones': {
    'zone1': 'Položka tabuľky 2',
    'zone2': 'Položka tabuľky 4',
    'zone3': 'Duch na cintoríne',
    'zone4': 'Zóna 4',
    'zone5': 'Zóna 5',
    'zone6': 'Zóna 6'
  },
  'tombstoneAdditions': {
    'light': 'Sviečka',
    'flower': 'Kytica',
    'stone': 'Kamieň',
    'other': 'Iné'
  },
  'periods': {
    '1-day': '1 deň',
    '30-days': '30 dní',
    '1-year': '1 rok',
    'forever': 'navždy'
  },
  'religions': {
    'catholicism': {
      'name': 'Katolícky'
    },
    'buddhism': {
      'name': 'Budhistický '
    },
    'bible_students_jehov': {
      'name': 'Svedkovia Jehovový'
    },
    'orthodoxy': {
      'name': 'Pravoslávny'
    },
    'hinduism': {
      'name': 'Hinduistický'
    },
    'islam': {
      'name': 'Islamistický'
    },
    'judaism': {
      'name': 'Judaistický'
    },
    'shinto': {
      'name': 'Shinto'
    },
    'atheism': {
      'name': 'Ateistický'
    },
    'romas': {
      'name': 'Rómsky'
    },
    'polish_tatars': {
      'name': 'Poľský Tatári'
    },
    'others': {
      'name': 'Iné'
    },
    'null': {
      'name': 'Mestský cintorín'
    },
    'none': 'Mestský cintorín'
  },
  'species': {
    'reptiles': {
      'name': 'Plazy'
    },
    'rodents': {
      'name': 'Hlodavce'
    },
    'horses': {
      'name': 'Kone'
    },
    'cats': {
      'name': 'Mačky'
    },
    'monkeys': {
      'name': 'Opice'
    },
    'insects': {
      'name': 'Hmyz'
    },
    'amphibians': {
      'name': 'Obojživelníky'
    },
    'dogs': {
      'name': 'Psy'
    },
    'birds': {
      'name': 'Vtáky'
    },
    'fishes': {
      'name': 'Ryby'
    },
    'mammals_others': {
      'name': 'Cicavce - iné'
    },
    'others': {
      'name': 'Iné'
    },
    'none': 'Žiadne'
  },
  'typeAmount': {
    'single': {
      'name': 'Slobodný'
    },
    'double': {
      'name': 'Dvojitý'
    },
    'multi': {
      'name': 'Viacosobový'
    }
  },
  'login': {
    'incorrect-email-password': 'nesprávne prihlasovacie meno alebo heslo',
    'success': 'Úspešne prihlásený'
  },
  'logout': {
    'success': 'Úspešne odhlásený'
  },
  'prices': {
    'title': 'Cenník'
  },
  'graves': {
    'single': 'Hrob pre jednu osobu',
    'multi': 'Hrob pre viac osôb',
    'additions': 'Dodatky'
  },
  'transactions': {
    'App\\Models\\Funerals\\Funeral': {
      'info': {
        'title': 'Hrob'
      },
      'model-created': 'Nový hrob',
      'model-extended': 'Rozšírenie hrobu'
    },
    'App\\Models\\Funerals\\Person': {
      'info': {
        'title': 'Pohreb'
      },
      'model-created': 'Nový pohreb',
      'model-extended': 'Rozšírenie hrobu'
    },
    'App\\Models\\Memory': {
      'info': {
        'title': 'Dodatok'
      },
      'model-created': 'Nový dodatok',
      'model-extended': 'Predĺženie príspevku'
    }
  },
  'validator': {
    'title': 'Nesprávne údaje'
  },
  'validation': {
    'email': 'Neplatný email',
    'confirm': 'Polia musia byť rovnaké',
    'unique': 'Toto pole musí byť jedinečné'
  }
}

export default language
